import request from '@/utils/request'

/** 用户信息 */
export function user() {
  return request({ url: `/system/user/info`, params: {}, method: 'get' })
}

/** 销售管控 */
export function control(param) {
  return request({ url: `/control/page`, params: param, method: 'get' })
}

/** 部门处置 */
export function task(param) {
  return request({ url: `/regulatory/task/detail/page?page=${param.page}&count=${param.count}`, params: param, method: 'get' })
}

/** 整改列表 */
export function disposalList(param) {
  return request({ url: `/disposal/page`, params: param, method: 'get' })
}

// 获取药店列表
export function storeList(param) {
  if (!param) { param = {} }
  return request({ url: `/screen/store/list?a=1`, params: param, method: 'get' })
}
// 获取药店分页列表
export function storeListPage(param) {
  return request({ url: `/store/page`, params: param, method: 'get' })
}

/*获取销售记录列表
@param {Object} param
@param {Number} param.hospital 1非互联网  2.互联网
*/
export function saleList(param) {
  param.scenes="dp"
  return request({ url: `/sale/page`, params: param, method: 'get' })
}
// 获取执业药师
export function pharmacistData(param) {
  param = param || {}
  return request({ url: `/pharmacist/photo/page`, params: param, method: 'get' })
}

//获取健康证
export function healthList(param) {
  return request({ url: `/health/credentials/page`, params: param, method: 'get' })
}
//获取经营许可证
export function licenceList(param) {
  return request({ url: `/licence/page`, params: param, method: 'get' })
}
//获取执业药师证
export function pharmacistList(param) {
  return request({ url: `pharmacist/photo/page`, params: param, method: 'get' })
}
//获取执业药师注册证
export function certList(param) {
  return request({ url: `/pharmacist/cert/page`, params: param, method: 'get' })
}
//获取药师证
export function juniorList(param) {
  return request({ url: `/pharmacist/junior/page`, params: param, method: 'get' })
}

/**
 * 模糊搜索
 * @param {*} param
 */
export function search(param) {
  return request({ url: '/erp/search', params: param, method: 'get' })
}

/**
 * 名称搜索（去除同名）
 * @param {*} param
 */
export function searchWord(param) {
  return request({ url: '/erp/search/word', params: param, method: 'get' })
}

/**
 * 添加控制规则
 * @param {*} data
 */
export function addData(data) {
  return request({ url: '/control/add', data: data, method: 'post' })
}
//预警信息列表
export function messagePage(param) {
  return request({ url: '/message/page', params: param, method: 'get' })
}

// 温湿度设备状态
export function equipment(param) {
  param = param || {}
  return request({ url: '/statistics/device/status', params: param, method: 'get' })
}
// 药事药店列表
export function visitor(param) {
  param = param || {}
  return request({ url: '/store/page', params: param, method: 'get' })
}

// 库存列表
export function stockList(param) {
  param = param || {}
  return request({ url: '/goods/lot/page', params: param, method: 'get' })
}
// 总库存
export function stockTotal(param) {
  param = param || {}
  return request({ url: '/screen/lot/num', params: param, method: 'get' })
}

// 企业动态评级
export function storeLevel(param) {
  param = param || {}
  return request({ url: '/store/level/page', params: param, method: 'get' })
}

// 店铺详情
export function storeDetail(param) {
  param = param || {}
  return request({ url: '/store/detail', params: param, method: 'get' })
}
// 店铺预警
export function storeMessage(param) {
  param = param || {}
  return request({ url: '/screen/v3/store/message', params: param, method: 'get' })
}
// 店铺温湿度
export function storeWsd(param) {
  param = param || {}
  return request({ url: '/screen/store/monitor/latest', params: param, method: 'get' })
}
// 获取视频
export function monitor(param) {
  param = param || {}
  return request({ url: '/store/monitor', params: param, method: 'get' })
}

// 企业执照
export function storeImage(param) {
  param = param || {}
  return request({ url: '/screen/store/image', params: param, method: 'get' })
}
// 企业执照列表
export function storePhoto(param) {
  param = param || {}
  return request({ url: '/store/photo/page', params: param, method: 'get' })
}
// 药师执照
export function juniorImage(param) {
  param = param || {}
  return request({ url: '/screen/store/junior', params: param, method: 'get' })
}
// 执业药师执照
export function pharmacistImage(param) {
  param = param || {}
  return request({ url: '/screen/store/pharmacist/image', params: param, method: 'get' })
}
// 执业药师执照(后台接口)
export function pharmacistPhoto(param) {
  param = param || {}
  return request({ url: '/pharmacist/photo/page', params: param, method: 'get' })
}
// 企业上报信息
export function feedback(param) {
  param = param || {}
  return request({ url: '/feedback/page', params: param, method: 'get' })
}


// 从业人员核酸总数
export function storePerson(param) {
  param = param || {}
  return request({ url: '/store/person/data', params: param, method: 'get' })
}
// 从业人员核酸列表
export function storePersonList(param) {
  param = param || {}
  return request({ url: '/store/person/page', params: param, method: 'get' })
}
// 从业人员核酸异常列表
export function storePersonListYc(param) {
  param = param || {}
  return request({ url: '/store/person/list', params: param, method: 'get' })
}
// 单店数据统计
export function storeTotal(param) {
  param = param || {}
  return request({ url: '/screen/store/total', params: param, method: 'get' })
}

// 疫情药品销售记录
export function yqypList(param) {
  param = param || {}
  return request({ url: '/sale/expand/warn/page', params: param, method: 'get' })
}

// 不合格药品记录
export function unqualified(param) {
  param = param || {}
  return request({ url: '/goods/unqualified/page', params: param, method: 'get' })
}

// 店铺管控药品销售情况
export function storeControl(param) {
  param = param || {}
  return request({ url: '/screen/store/data', params: param, method: 'get' })
}

// 处方销量
export function prescriptionTotal(param) {
  param = param || {}
  return request({ url: '/screen/prescription/total', params: param, method: 'get' })
}

/**
 * 获取预警数 today:1为今日
 * @param {today:1} param
 */
export function todayWarning(param) {
  param = param || {}
  return request({ url: '/screen/v3/message/size', params: param, method: 'get' })
}

//采购数量统计
export function purchase(param) {
  param = param || {}
  return request({ url: '/screen/purchase/total', params: param, method: 'get' })
}
//采购列表
export function purchaseList(param) {
  param = param || {}
  return request({ url: '/purchase/page', params: param, method: 'get' })
}

//销售总数
export function sale(param) {
  param = param || {}
  return request({ url: '/screen/v3/sale/total', params: param, method: 'get' })
}

//执业药师在岗时长
export function signTime(param) {
  param = param || {}
  return request({ url: '/sign/time/page', params: param, method: 'get' })
}

//风险预警排行
export function riskList(param) {
  param = param || {}
  return request({ url: '/screen/risk/list', params: param, method: 'get' })
}

//温湿度设备
export function wsdList(param) {
  param = param || {}
  return request({ url: 'monitor/page', params: param, method: 'get' })
}

//云药房
export function yunStore(param) {
  param = param || {}
  return request({ url: 'https://api.zlyd.xusos.com/shop/page?lng=0&lat=0', params: param, method: 'get' })
}
//进销存库存
export function getStore(param) {
  param = param || {}
  return request({ url: '/goods/lot/page', params: param, method: 'get' })
}
//购进库存
export function bugNIngList(param) {
  param = param || {}
  return request({ url: '/purchase/page', params: param, method: 'get' })
}
//销售记录
export function seleList(param) {
  param = param || {}
  return request({ url: '/sale/page', params: param, method: 'get' })
}

//ppt
export function pptList() {
  return request({ url: '/upload/list?dir=yaojian%2Fresource', params: {}, method: 'get' })
}

//药店数
export function screenCount(param) {
  param = param || {}
  return request({ url: `/screen/count`, params: param, method: 'get' })
}

//成交数量
export function saleSum(param) {
  param = param || {}
  return request({ url: `/screen/sale/sum`, params: param, method: 'get' })
}
//店铺动态数据
export function storeDynamic(param) {
  param = param || {}
  return request({ url: `/screen/store/dynamic`, params: param, method: 'get' })
}

//各县市药店数
export function storeCount(param) {
  param = param || {}
  return request({ url: `/screen/store/count`, params: param, method: 'get' })
}
//麻黄碱和兴奋剂销售数量
export function mhjSaleNum(param) {
  param = param || {}
  return request({ url: `/screen/store/data`, params: param, method: 'get' })
}
//累计整改数
export function disposalNum(param) {
  param = param || {}
  return request({ url: `/disposal/page?page=1&count=10&storeId=0&storeName=&type=&statusText=30,40`, params: param, method: 'get' })
}
//预警数量
export function messageNum(param) {
  param = param || {}
  return request({ url: `/message/page`, params: param, method: 'get' })
}
//网络销售
export function saleOnline(param) {
  param = param || {}
  return request({ url: `/store/page`, params: param, method: 'get' })
}
//网订店送店铺
export function storeDelivery(data) {
  data = data || {}
  return request({ url: `/storeDelivery/listPage`, params: data,data, method: 'post' })
}
//药事服务站点
export function pharmacyServiceStation(data) {
  data = data || {}
  return request({ url: `/pharmacyServiceStation/listPage`, params: data,data, method: 'post' })
}
//上山进岛
export function mountainIsland(data) {
  data = data || {}
  return request({ url: `/mountainIsland/listPage`, params: data,data, method: 'post' })
}
//特殊服务
export function nonprofitApplication(data) {
  data = data || {}
  return request({ url: `/nonprofitApplication/listPage`, params: data,data, method: 'post' })
}
//监管档案
export function regulatoryTask(param) {
  param = param || {}
  return request({ url: `/regulatory/task/detail/page?statusText=30,35,40&kk=1`, params: param, method: 'get' })
}
//评价
export function evaluate(param) {
  param = param || {}
  return request({ url: `/evaluate/page?page=1&count=10&type=10&storeName=`, params: param, method: 'get' })
}
//点击量
export function clickNumber(param) {
  param = param || {}
  return request({ url: `/screen/hits`, params: param, method: 'get' })
}
//智能药箱
export function chest(param) {
  param = param || {}
  return request({ url: `/screen/chest`, params: param, method: 'get' })
}
//智能药箱数量
export function chestNum(param) {
  param = param || {}
  return request({ url: `/screen/chest/count`, params: param, method: 'get' })
}

//合麻黄碱药品销售
export function mhjSale(data) {
  data = data || {}
  return request({ url: `/sale/expand/warn/page?type=2`, params: data, method: 'get' })
}
//合兴奋剂药品销售
export function xfjSale(data) {
  data = data || {}
  return request({ url: `/sale/expand/warn/page?type=3`, params: data, method: 'get' })
}
//重点企业
export function zdqyList(data) {
  data = data || {}
  return request({ url: `/store/mark/listPage`, params: data,data, method: 'post' })
}
//自查情况
export function zichaList(data) {
  data = data || {}
  return request({ url: `/store/form/listPage`, params: data, data,method: 'post' })
}
//承诺情况
export function chengnuoList(data) {
  data = data || {}
  return request({ url: `/commitment/store/listPage?storeName=&commitmentId=1670596036509024257`, params: data, data,method: 'post' })
}
//加分审核列表
export function addAuditList(data) {
  data = data || {}
  return request({ url: `/nonprofitApplication/listPage`, params: data, data,method: 'post' })
}
//加分审核
export function addAudit(data) {
  data = data || {}
  return request({ url: `/nonprofitApplication/approved`, params: data, data,method: 'get' })
}

//扣分记录
export function DeductionList(data) {
  data = data || {}
  return request({ url: `/store/score/record/page`, params: data, data,method: 'get' })
}

export function userInfo(data) {
  data = data || {}
  return request({ url: `/system/user/info`,params: {}, data,method: 'get' })
}

/**
 * 店铺信用等级分组统计
 * @param {*} params 
 */
export function groupStoreLevelApi(params) {
  return request({
    url: '/screen/group/store/level',
    method: 'get',
    params
  })
}

/**
 * 首页中间顶部数据
 * @param {*} params 
 * @returns 
 */
export function getHomeCenterTopApi(params) {
  return request({
    url: '/screen/home/center/top',
    method: 'get',
    params
  })
}

/**
 * 分页列表options数据
 * @param {*} params
 */
export function pageByOptionsApi(params) {
  return request({ url: '/store/list/page', params, method: 'get' })
}
